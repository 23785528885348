
html{
  scroll-behavior: smooth;
}

:root{
    --background-main: #333645;
    --text-color-yellow: #ffaa06;
    --text-color-grey: #a8a7b0;
  }

  .button:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-text {
    text-align: center;
    color: #61dafb;
  }
  
  .landing-silver {
    background-color: #333645;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  input:-internal-autofill-selected {
    background: transparent !important;
    background-color: transparent !important;
  }
  
  input:-webkit-autofill {
    -webkit-transition-delay: 9999999s !important;
  }
  
  /* ========== Custom Scroll Styles =========== */
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #333645;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #282a37;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #282a37;
  }
  

  /* Css For All Modal Same Background And Images Logo Start */
.input-feild-button .text-feild-modal .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
  border: 1px solid #ffffff;
}
.modal-open .form-modal-landing-silver .input-feild-button .button-form-modal{
  background-color: #ffaa06;
  border: none;
  border-radius: 36px;
  color: #fff;
  padding: 10px 55px;
  margin-top: 8px;
  outline: none !important;
    box-shadow: none !important;
}
.modal-open .form-modal-landing-silver .input-feild-button .text-feild-modal .MuiFormControl-root{
  width: 100%;
  
}
.modal-footer{
  display: none!important;
}
.modal-open .modal-header{
  border: none;
}
.modal-open .form-modal-landing-silver .input-feild-button .text-feild-modal .MuiFormControl-root .MuiInputBase-root{
  color: #ffffff;
  border-radius: 50px;
  padding-bottom: 5px;
}

.modal-open .form-modal-landing-silver .input-feild-button .text-feild-modal .MuiFormControl-root label{
  color: #ffffff;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 10px;
  font-family: 'lato';
  font-weight: 400;
 
}
.modal-open .modal-content{
  border-radius: 50px;
  position: relative;
}
.modal-header .close{
  padding: 20px 14px!important;
}
.modal-header button.close{
  opacity: 1;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  text-shadow: none;
  font-weight: 400;
  z-index: 1111;
  font-size: 32px;
}
.modal-header button{
  outline: none;
  box-shadow: none;
}
.modal-open .modal-dialog{
  margin: 230px auto;
  -webkit-box-shadow: 0px 4px 33px 3px rgba(0,0,0,0.60);
  -moz-box-shadow: 0px 4px 33px 3px rgba(0,0,0,0.60);
  shadow: 0px 4px 33px 3px rgba(0,0,0,0.60);
}
.modal-open .modal-body{
  padding-bottom: 50px;
}

.modal-open .form-modal-landing-silver .input-feild-button .text-mid-pr {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.modal-open .form-modal-landing-silver .input-feild-button{
  padding-top: 50px;
}

.modal-open .form-modal-landing-silver .input-feild-button .text-mid-pr p span {
  font-weight: 600;
  font-size: 15px;
}


.modal-open .register-modal {
  position: relative;
  border-radius: 50px;
}
.modal-open .register-modal .icon-area-modal-pur{
  position: absolute;
    top: -2px;
    left: 163px;
}
.modal-open .register-modal .icon-area-modal-pur img{
  width: 48%;
  padding-top: 40px;
  text-align: center;
}

.modal-open .register-modal .icon-area-modal-pur{
  text-align: center;
}
.modal-open .modal-dialog{
  margin: 182px auto;
  max-width: 662px;
}
.modal-open .modal-content{
  background-image: url(../../static/images/landing/modal-back.png);
 background-size: cover;
 width: 100%;
 border: none;
 background-position: top center;
}
@media(max-width: 1920px){
  .modal-open .register-modal .icon-area-modal-pur{
    left: 68px;
  }
}
@media(max-width: 425px){
  .signup-modal .connect-wallat-signup .meta-icon-imag-text {
    display: block;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup {
    margin-left: 0px;
  }
}
@media(min-width: 425px) and (max-width: 576px){
  .modal-open .register-modal .icon-area-modal-pur {
    left: 0px;
}
  .modal-open .register-modal .icon-area-modal-pur img{
    width: 59%;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h2{
    padding-top: 28px;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text img{
    width: 46%;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h2{
    /* padding-left: 14px; */
  }
}

@media(min-width: 375px) and (max-width: 424px){
  .modal-open .register-modal .icon-area-modal-pur{
    left: -2px;
  }
  .modal-open .register-modal .icon-area-modal-pur img{
    width: 66%;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text img{
    width: 32%;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h2{
    padding-top: 10px;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h5 span img{
    width: 8%;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h5{
    padding-left: 8px;
  }
}

@media(min-width: 320px) and (max-width: 374px){
  .modal-open .form-modal-landing-silver .input-feild-button {
    padding-top: 26px;
}
  .modal-open .register-modal .icon-area-modal-pur{
    left: -6px;
  }
  .connect-modal .form-modal-landing-silver h4{
    padding-top: 50px;
  }
  .modal-open .register-modal .icon-area-modal-pur img{
    width: 69%;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text img{
    width: 82px;
    height: 82px;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h5 span img{
    width: 15px;
    height: 15px;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h2{
   
    font-size: 13px;
  }
  .signup-modal .connect-wallat-signup .meta-icon-imag-text .text-meta-mask-signup h5{
    font-size: 13px;
  }
  .signup-modal .form-modal-landing-silver .input-feild-button .button-form-modals{
    font-size: 12px;
    padding: 10px 34px;
  }
}