
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,900;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url("https://use.typekit.net/mio5uxr.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

:root{
    --background-main: #333645;
    --text-color-yellow: #ffaa06;
    --text-color-grey: #a8a7b0;
  }
.footer-fot .footer{background-color: #282a37;padding-top: 60px;}
.footer-fot{position: relative;}
.footer-fot .footer h5{font-family: 'lato'; font-weight: 500; font-size: 26px;}
.footer-fot .buttonIn { position: relative;} 
.footer-fot .buttonIn input { margin: 0px; padding: 0px; width: 100%; outline: none; height: 48px; border-radius: 55px; border: none;padding-left: 40px;font-size: 16px;font-family: 'lato';font-weight: 400;} 
.footer-fot .buttonIn button {box-shadow: none; outline: none; position: absolute;  border-radius: 50px; right: 1px; top: 2px; z-index: 2; border: none;  right: 1px;cursor: pointer; color: white; background-color: #ffaa06; transform: translateX(2px);padding: 12px 30px;font-size: 16px;font-weight: 500;font-family: 'lato';font-weight: 400;}
.footer-fot .buttonIn i{padding-right: 7px;}
.footer-fot .button-fot{padding-top: 25px; width: 40%; margin: 0 auto;}
.footer-fot .footer h6{font-family: 'lato'; font-weight: 500; font-size: 14px;}
.footer-fot .bullet_pointer ul li{list-style: none;line-height: 30px;}
.footer-fot .bullet_pointer ul {padding-top: 15px;}
.footer-fot .bullet_pointer  h3{font-size: 21px;font-family: 'lato';font-weight: 500;padding-top: 40px;}
.footer-fot .bullet_pointer ul li a{text-decoration: none; color: white;font-size: 16px;font-family: 'lato';font-weight: 400;}
.footer-fot .bullet_pointer ul li a:hover{color: #ffaa06;}
.footer-fot .image-footer img{position: absolute;top: 10%;left: 5%;}
.footer-fot .copy-right{padding-top: 50px;padding-bottom: 40px;}
.footer-fot .rightside-footer .footer-socialss{height: 40px; width: 40px; border: 1px solid #ffffff; display: flex; justify-content: center; align-items: center;margin-right: 10px;border-radius: 50px;font-size: 18px;}
.footer-fot .rightside-footer .footer-socialss a{ display: flex; justify-content: center; align-items: center;padding-left: 24px; color: #ffffff;}
.footer-fot .leftside-footer p{font-size: 16px;font-family: 'lato';font-weight: 500;}
.footer-fot .rightside-footer p{font-size: 20px;font-family: 'lato';font-weight: 400; padding-right: 30px;padding-top: 7px;}
.footer-fot .rightside-footer .social-ones:hover{border-color: #00acee;}
.footer-fot .rightside-footer .social-twos:hover{border-color: #4267B2;}
.footer-fot .rightside-footer .social-threes:hover{border-color: #E60023;}
.footer-fot .rightside-footer .social-ones:hover a.social-one {color: #00acee;}
.footer-fot .rightside-footer .social-twos:hover a.social-two{color: #4267B2;}
.footer-fot .rightside-footer .social-threes:hover a.social-three{color: #E60023;}
/* Media query */

@media(min-width: 992px) and (max-width: 1199px){
.footer-fot .buttonIn button{top: 0px;}
}

@media(min-width: 768px) and (max-width: 992px) {
  .footer-fot .button-fot {width: 53%;}
  .footer-fot .buttonIn button{top: 0px;}
  .footer-fot .footer-media-1 {text-align: center;}
}
@media(max-width: 576px){
  .footer-fot .leftside-footer p{text-align: center;}
  .footer-fot .bullet_pointer ul li{text-align: center;}
  .footer-fot .bullet_pointer h3{text-align: center;}
}
@media(min-width: 576px) and (max-width: 768px) {
.footer-fot .footer h5 { font-size: 26px;}
.footer-fot .image-footer img { top: 4%;  width: 10%;}
.footer-fot .button-fot { width: 62%;}
.footer-fot .bullet_pointer h3{font-size: 22px;}
.footer-fot .buttonIn button{top: 0px;}
.footer-fot .bullet_pointer ul li a{font-weight: 300;font-size: 16px;}
.footer-fot .rightside-footer p{font-size: 20px;white-space: nowrap;}
}
@media(min-width: 425px) and (max-width: 575px) {
.footer-fot .buttonIn input{font-size: 15px;height: 46px;}
.footer-fot .image-footer img{top: 3%;}
.footer-fot .footer h5{font-size: 24px;}
.footer-fot .footer h6{font-size: 12px;padding-left: 10px;}
.footer-fot .buttonIn button{top: 0px;}
.footer-fot .button-fot{width: 80%;}
.footer-fot .bullet_pointer h3{font-size: 22px;}
.footer-fot .bullet_pointer ul li a{font-weight: 300;font-size: 16px;}
.footer-fot .rightside-footer p{font-size: 22px;white-space: nowrap;}
.footer-fot .rightside-footer img{margin-top: 8px;    width: 35px;height: 35px;}
.footer-fot .rightside-footer img:nth-child(3){margin-top: 8px;}
}
@media(min-width: 320px) and (max-width: 425px) {
.footer-fot .image-footer img{top: 3%;}
.footer-fot .footer h6{font-size: 12px;}
.footer-fot .footer h5{font-size: 22px;}
.footer-fot .buttonIn input{font-size: 14px;padding-left: 21px;height: 45px;}
.footer-fot .buttonIn button{top: 0px;}
.footer-fot .button-fot{width: 94%;}
.footer-fot .buttonIn button{padding: 12px 22px;font-size: 14px;}
.footer-fot .bullet_pointer h3{font-size: 22px;}
.footer-fot .bullet_pointer ul li a{font-weight: 300;font-size: 16px;}
.footer-fot .rightside-footer p{font-size: 18px;}
.footer-fot .rightside-footer img{margin-top: 8px;    width: 35px;height: 35px;}
.footer-fot .rightside-footer img:nth-child(3){margin-top: 8px;}
}
@media(min-width: 320px) and (max-width: 374px) {
  .footer-fot .rightside-footer .rightside-footer{padding-right: 36px!important;}
  .landing-silver .card_latests{
    padding: 58px 64px;
  }
.footer-fot .buttonIn button{top: 0px;}
.footer-fot .buttonIn button{padding: 12px 16px;font-size: 14px;}
}
@media(min-width: 375px) and (max-width: 425px){
  .footer-fot .rightside-footer p{font-size: 22px;white-space: nowrap;}
}